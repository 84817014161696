<template>
  <div class="">
    <el-upload
      v-if="multiple"
      action
      :disabled="disabled"
      list-type="picture-card"
      :file-list="fileList"
      :accept="accept"
      :on-remove="handleRemove"
      :multiple="multiple"
      :limit="limit"
      :before-upload="beforeUpload"
      :http-request="httpRequest"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog> -->
    <el-upload
      v-else
      class="avatar-uploader"
      action
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
    >
      <div v-if="imageUrl" @mouseover="hover = true" @mouseleave="hover = false">
        <img :src="imageUrl" class="avatar" />
        <div
          v-if="!disabled"
          class="make"
          :class="hover && 'make--show'"
          @click.stop="handleRemove(imageUrl)"
        >
          <i class="el-icon-delete"></i>
        </div>
      </div>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
export default {
  props: {
    files: {
      type: [Array, String],
      default: null,
    },
    accept: {
      type: String,
      default: '.jpg,.png,.jpeg',
    },
    size: {
      type: Number,
      default: 10,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
    isPng: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ossClient: {},
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
      imageUrl: '',
      hover: false,
    }
  },
  computed: {
    ...mapGetters(['getClient']),
  },
  watch: {
    files: {
      handler(val) {
        if (!this.multiple) {
          return (this.imageUrl = val || '')
        } else {
          this.fileList =
            val?.map((url) => {
              return {
                url,
              }
            }) || []
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {},
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeUpload(file) {
      let isLt2M = false

      isLt2M = file.size / 1024 / 1024 < this.size
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 ${this.size}MB!`)
      } else {
        this.loading = true
      }

      return isLt2M
    },
    httpRequest(file) {
      console.log(file)
      this.ossUploadImg(file.file.name, file.file).then((res) => {
        if (this.multiple) {
          this.$emit('upload', [...this.files, res])
        } else {
          this.imageUrl = res
          this.$emit('upload', res)
        }
      })
    },
    async ossUploadImg(filename, file) {
      const date = dayjs().format('YYYY-MM-DD')
      const fileName = filename.split('.')
      const imgFilename =
        '/APPimage/' +
        date +
        '/' +
        fileName[0] +
        '-' +
        new Date().getTime() +
        (this.isPng ? '.' + fileName[1] : '.jpeg')

      const imgHeader = { 'x-oss-object-acl': 'public-read' }

      const imgResult = await this.getClient.put(imgFilename, file, {
        headers: imgHeader,
      })
      return imgResult.url
    },
    handleRemove(file) {
      if (this.disabled) return
      console.log(file)
      if (this.multiple) {
        const data = this.files.filter((e) => e !== file.url)
        this.$emit('upload', data)
      } else {
        this.$emit('upload', '')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.avatar-uploader {
  position: relative;
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
}
.make {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  // transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  &--show {
    z-index: 2;
    opacity: 1;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
</style>
