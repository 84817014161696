import request from "@/utils/request";

// 专题列表
export function getSpecialList(data) {
  return request({
    url: "/marketing-web-api/admin/theme/getList",
    method: "POST",
    data,
  });
}
// 上、下架专题
export function updateUpOrDown(data) {
  return request({
    url: "/marketing-web-api/admin/theme/rackUpAndDown",
    method: "POST",
    data,
  });
}
// 删除专题
export function deleteSpecial(data) {
  return request({
    url: "/marketing-web-api/admin/theme/delete",
    method: "POST",
    data,
  });
}
// 批量删除专题
export function deleteSpecialAll(data) {
  return request({
    url: "/marketing-web-api/admin/theme/deleteByIds",
    method: "POST",
    data,
  });
}
// 查询专题详情
export function getDetailConfig(data) {
  return request({
    url: "/marketing-web-api/admin/theme/detailConfig",
    method: "POST",
    data,
  });
}
// 添加专题
export function saveSpecial(data) {
  return request({
    url: "/marketing-web-api/admin/theme/save",
    method: "POST",
    data,
  });
}
// 更新专题配置
export function saveSpecialConfig(data) {
  return request({
    url: "/marketing-web-api/admin/theme/saveConfig",
    method: "POST",
    data,
    headers: { "Content-Type": "application/json; charset=UTF-8" },
  });
}
