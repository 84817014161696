<template>
  <div id="container" class="container" :class="{ 'micro-app': microApp }">
    <div class="main">
      <div class="main-tools">
        <div class="header-left">
          <div class="header-close" @click="goBack">
            <i class="header-close__icon icon-market icon-fanhui1" alt="" />
            <div class="header-close__text">取消</div>
          </div>

          <div class="header-edit-size">画布尺寸：375 * 812</div>
          <div class="scale">
            <i
              class="scale__minus el-icon-minus"
              :style="{
                color: scale === 0 ? '#999' : '#000',
              }"
              @click="onScale('minus')"
            ></i>
            <div class="scale__number">
              {{ `${scale}%` }}
            </div>
            <i
              class="scale__plus el-icon-plus"
              :style="{
                color: scale === 100 ? '#999' : '#000',
              }"
              @click="onScale('plus')"
            ></i>
          </div>
        </div>
        <tools
          :is-push.sync="isPush"
          :index="index"
          @selectCom="selectCom"
          @onScrollTo="onScrollTo"
        />
      </div>

      <!-- 画布 -->
      <div class="edit-container" :style="`transform:scale(${scale / 100})`">
        <div
          id="edit"
          ref="edit"
          class="edit"
          @drop="handleDrop"
          @dragover="handleDragOver"
          @contextmenu="editContextmenu"
          @click.stop="menuShow = false"
        >
          <!-- 顶部导航栏 -->
          <ty-navbar :pages="pages" />
          <!-- 编辑器 -->
          <editer :menu-show.sync="menuShow" :is-show="isShow" />
        </div>
        <!-- 去顶部 -->
        <div class="edit-container__up" @click="onScrollTo('up')">
          <i class="el-icon-arrow-up"></i>
        </div>
        <!-- 去底部 -->
        <div class="edit-container__down" @click="onScrollTo('down')">
          <i class="el-icon-arrow-down"></i>
        </div>
      </div>
      <div class="main-config">
        <div class="header-right">
          <el-button size="mini" @click="resize">重置</el-button>
          <el-button size="mini" :loading="subLoading" @click="submit()">保存</el-button>
          <el-button type="primary" size="mini" :loading="subLoading" @click="submit('up')"
            >立即发布</el-button
          >
        </div>
        <!-- 右侧组件配置 -->
        <config :pages="pages" />
      </div>
    </div>
  </div>
</template>

<script>
import config from './components/config/index.vue'
import TyNavbar from '@/components/ty-navbar/index.vue'
import tools from './components/tools/index.vue'
import editer from './components/editer/index.vue'
import { guid, addUnit } from '@/utils/index.js'
import { getDetailConfig, saveSpecialConfig } from '@/api/special.js'
import { mapState } from 'vuex'
export default {
  name: 'HomeView',
  components: {
    TyNavbar,
    config,
    tools,
    editer,
  },
  data() {
    return {
      addUnit,
      // 页面配置
      pages: {
        title: '',
      },
      index: 0, // 当前被拖动的组件下标
      isPush: false, // 防止重复push组件至画布
      isShow: true, // 防止页面未更新
      activeName: 'com',
      // temporary: {}, //临时样式
      menuShow: false,
      contextMenuData: {}, // 右击弹窗的数据
      specialId: '', // 当前专题的id
      subLoading: false,
      resetComponents: [],
      microApp: false,
      scale: 100,
    }
  },
  computed: {
    ...mapState(['components', 'selectComponent', 'selectIndex', 'temporary']),
  },
  created() {
    if (process.env.NODE_ENV !== 'development') this.microApp = true
    // 进入页面清除vuex中的数据
    this.resize()
  },

  mounted() {
    this.specialId = this.$route.query.id
    this.getData()
    const { height } = document.getElementById('container').getBoundingClientRect()
    console.log('container', height)
    if (height <= 800) this.scale = 90
    if (height <= 750) this.scale = 85
    if (height < 700) this.scale = 80
    if (height >= 850) this.scale = 100
  },

  methods: {
    getData() {
      getDetailConfig({ data: this.specialId }).then((res) => {
        let components = []
        this.pages.title = res.data.themeTitle
        if (!res.data.configs) return (components = [])
        components = res.data.configs.map((e) => {
          e.status = 2
          e.setting = JSON.parse(e.setting)
          return e
        })
        this.$store.commit('SET_COMPONENTS', components)
        this.resetComponents = JSON.parse(JSON.stringify(components))
        this.selectCom({ item: components[0], index: 0 })
      })
    },
    /**
     * 工具栏中的组件在画布中释放
     */
    handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()
      if (!this.temporary) return
      if (this.temporary.component === 'TyTransparent') return this.handlerSuspension(e)
      this.handlerNoSuspension(e)
    },
    /**
     * 处理默认为悬浮的元素
     * 根据鼠标位置放置元素
     */
    handlerSuspension(e) {
      const temporary = JSON.parse(JSON.stringify(this.temporary))
      temporary.setting.style.top = e.offsetY
      temporary.setting.style.left = e.offsetX
      temporary.status = 2
      temporary.setting.props.uuid = guid(8)
      this.$store.commit('SET_COMPONENTS', [...this.components, temporary])
      this.$store.commit('SET_TEMPORARY', null) // 清空拖拽临时数据
      this.isPush = false
      this.selectCom({
        item: this.components[this.components.length - 1],
        index: this.components.length - 1,
      })
    },
    /**
     * 处理默认为非悬浮的元素
     * push到底部或者根据鼠标是否在默认元素的上下两侧，push到对应的位置
     * 当前函数只是将已经push的元素固定(status = 2)
     */
    handlerNoSuspension(e) {
      this.components[this.index].status = 2 // 标识该组件在画布中释放的
      this.components[this.index].setting.props.uuid = guid(8)
      this.$store.commit('SET_TEMPORARY', null) // 清空拖拽临时数据
      this.isPush = false
      // 防止视图不刷新
      this.isShow = false
      this.isShow = true
      this.selectCom({
        item: this.components[this.index],
        index: Number(this.index),
      })
      this.onScrollTo(this.components[this.index].setting.props.uuid)
    },
    /**
     * 工具栏中的组件在画布中移动
     */
    handleDragOver(e) {
      e.preventDefault()
      e.stopPropagation()
      // 这里 没有event/没有拖拽中的元素/透明按钮都会结束当前方法
      if (
        !e.target?.className ||
        this.temporary?.component === 'TyTransparent' ||
        !this.temporary?.component
      ) {
        return
      }
      const className = e.target?.className
      const cl = className.split(' ')
      const name = cl.includes('edit-item') ? 'edit-item' : 'edit'

      if (name === 'edit') {
        // 鼠标位置处于画布上，未处于其他组件上
        if (this.isPush) return // 防止每次移动重复将组件push进画布
        this.isPush = true

        this.index = this.components.length
        this.temporary.status = 1
        const components = this.components
        components.push(JSON.parse(JSON.stringify(this.temporary)))
        this.$store.commit('SET_COMPONENTS', components)
      } else if (name === 'edit-item') {
        // 鼠标位置处于其他组件上
        const target = e.target
        let [y, h, curIndex] = [e.offsetY, target.offsetHeight, target.dataset.index || 0]
        const direction = y < h / 2
        // 防止重复添加
        if (!this.isPush) {
          this.temporary.status = 1
          const component = JSON.parse(JSON.stringify(this.temporary))
          if (direction) {
            if (curIndex === 0) {
              this.components.unshift(component)
            } else {
              this.components.splice(curIndex, 0, component)
            }
          } else {
            curIndex = +curIndex + 1
            this.components.splice(curIndex, 0, component)
          }
        } else {
          let result = null
          if (direction) {
            const i = curIndex === 0 ? 0 : curIndex - 1
            result = this.components[i].status === 1
          } else {
            const i = +curIndex + 1
            result = this.components.length > i && this.components[i].status === 1
          }
          if (result) return

          const temp = this.components.splice(this.index, 1)
          this.components.splice(curIndex, 0, temp[0])
        }
        this.index = curIndex
        this.isPush = true
      }
    },

    /**
     * 点击画布中的组件，
     * 右侧显示当前组件的设置项
     */
    selectCom(e) {
      this.$store.commit('SET_SELECT_COMPONENTS', e)
      this.menuShow = false
    },
    /**
     * 删除画布中的指定组件
     */
    editItemDel(index) {
      this.components.splice(index, 1)
    },
    /**
     * 重置画布
     */
    resize() {
      this.$store.commit('SET_TEMPORARY', {}) // 清空拖拽临时数据
      this.$store.commit('SET_SELECT_COMPONENTS', { item: {}, index: 0 })
      this.$store.commit('SET_COMPONENTS', JSON.parse(JSON.stringify(this.resetComponents)))
      this.$forceUpdate()
    },

    /**
     * 编辑器容器，禁止鼠标右击默认事件
     */
    editContextmenu(e) {
      e.stopPropagation()
      e.preventDefault()
    },

    /**
     * 保存、立即发布
     */
    submit(up) {
      let components = JSON.parse(JSON.stringify(this.components))
      // 调用验证必填项
      if (!this.handlerVerification(components)) {
        return this.$message.error('请在右侧将标注了*的数据补充完整！')
      }
      // 处理数据为后端需要的格式
      components = components.map((e) => {
        const { setting, status, ...item } = e
        return { ...item, setting: JSON.stringify(setting) }
      })
      this.subLoading = true
      saveSpecialConfig({
        data: {
          themeId: this.specialId,
          themeTitle: this.pages.title,
          publishOrSave: up ? '1' : '2',
          configs: components,
        },
      })
        .then((res) => {
          this.goBack()
          this.$message.success(`${up ? '发布成功！' : '保存成功！'}`)
        })
        .finally(() => {
          this.subLoading = false
        })
    },
    goBack() {
      this.$router.go(-1)
    },
    /**
     * 循环遍历数据
     * 验证每条数据的必填项是否填写了数据
     * 并将验证失败的数据的表单显示右侧
     */
    handlerVerification(c) {
      let adopt = true
      for (let index = 0; index < c.length; index++) {
        const item = c[index]
        if (item.component === 'TyButton' && !item.setting.props.value) {
          this.selectCom({ item, index })
          return (adopt = false)
        }
        if (
          item.component === 'TyImage' &&
          (!item.setting.props.value.length || !item.setting.style.width)
        ) {
          this.selectCom({ item, index })
          return (adopt = false)
        }
        if (item.component === 'TyTabs') {
          if (!item.setting.style.width) {
            this.selectCom({ item, index })
            return (adopt = false)
          } else {
            const items = item.setting.item.some((e) => !e.width || !e.url || !e.activeUrl)
            this.selectCom({ item, index })
            return !items
          }
        }
      }
      return adopt
    },
    /**
     * 缩放画布大小
     */
    onScale(e) {
      if (e === 'plus' && this.scale === 100) return
      if (e === 'minus' && this.scale === 0) return
      e === 'minus' ? (this.scale -= 5) : (this.scale += 5)
    },
    onScrollTo(e) {
      if (!e) return
      // 滚动至画布顶部/底部
      if (e === 'down' || e === 'up') {
        const doc = document.getElementById('draggable')
        doc.scrollTo({
          top: e === 'down' ? doc.scrollHeight : 0,
          behavior: 'smooth',
        })
      } else {
        // 滚动至指定元素位置
        this.$nextTick(() => {
          document
            .querySelector('#' + e)
            .scrollIntoView(false, { behavior: 'smooth', block: 'center' })
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  min-width: 1200px;
  // min-height: 884px;
  display: flex;
  flex-direction: column;
}
.micro-app {
  height: calc(100vh - 70px);
  border-radius: 24px;
  margin: 10px 0;
}
.header {
  height: 50px;
  width: 100%;
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  z-index: 1;
  background-color: transparent;
  &-left {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    box-sizing: border-box;
  }
  &-edit-size {
    margin-left: 40px;
    font-size: 12px;
    color: #000;
  }
  &-right {
    // flex: 1;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  &-close {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__icon {
      font-size: 18px;
      margin-right: 4px;
    }
    &__text {
      font-size: 14px;
      color: #000;
      font-weight: 400;
    }
  }
}

.main {
  width: 100%;
  height: 100%;
  // padding-top: 50px;
  // flex: 1 0 852px;
  // min-height: 852px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &-tools {
    height: 100%;
  }
  &-config {
    height: 100%;
  }
}
.content {
  width: 375px;
  height: 844px;
}
.edit-container {
  position: relative;
  padding: 10px 0;
  // top: 20px;
  // left: 50%;
  // transform: translateX(-50%);
  &__size {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__up {
    position: absolute;
    right: -50px;
    top: 400px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    &:hover {
      background: linear-gradient(145deg, #e6e6e6, #ffffff);
      box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
    }
  }
  &__down {
    position: absolute;
    right: -50px;
    top: 460px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    &:hover {
      background: linear-gradient(145deg, #e6e6e6, #ffffff);
      box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
    }
  }
}
.edit {
  width: 375px;
  height: 812px;
  background-color: #fff;
  // margin-top: 10px;
  transform: translate3D(0, 0, 0);
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.scale {
  display: flex;
  align-items: center;
  margin-left: 30px;
  color: #000;
  &__number {
    margin: 0 12px;
    font-size: 14px;
    width: 50px;
    text-align: center;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
</style>
