<template>
  <div class="ty-button" :class="['ty-button--' + setting.props.size]"></div>
</template>
<script>
export default {
  props: {
    setting: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      prop: {},
      style: {},
    };
  },
  watch: {
    setting: {
      handler(val) {
        this.prop = val?.props || {};
        this.style = val?.style || {};
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.button-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 按钮样式
.ty-button {
  position: relative;
  height: 44px;
  margin: 0;
  padding: 0;
  cursor: move;
  background-color: rgba(0, 0, 0, 0.1);
  &--large {
    width: 60px;
    height: 70px;
  }
  &--normal {
    width: 40px;
    height: 50px;
  }
  &--small {
    height: 25px;
    width: 60px;
  }
  &--mini {
    height: 25px;
    width: 38px;
  }
}
</style>
