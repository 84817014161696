<template>
  <div>
    <div
      class="navbar"
      :style="{
        backgroundColor: '#fff',
      }"
    >
      <div class="navbar-statusbar"></div>
      <div class="navbar-mian">
        <div class="icon-market icon-fanhui navbar-back"></div>
        <div class="navbar__title">
          {{ pages.title }}
        </div>
        <div class="navbar-capsule">
          <i class="icon-market icon-gengduo navbar-capsule__gengduo"></i>
          <i class="icon-market icon-danxuan-xuanzhong navbar-capsule__xuanzhong"></i>
        </div>
      </div>
    </div>
    <!-- 是否高度塌陷 -->
    <div class="occupy"></div>
  </div>
</template>
<script>
export default {
  props: {
    pages: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.navbar {
  width: 375px;
  background-color: #fff;
  position: fixed;
  z-index: 99;
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  &__title {
    font-size: 16px;
    color: #333;
  }
  &-statusbar {
    height: 22px;
  }
  &-mian {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    height: 44px;
    position: relative;
  }
  &-back {
    position: absolute;
    left: 12px;
    top: 50%;
    color: #999;
    transform: translateY(-50%);
  }
  &-capsule {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 87px;
    height: 32px;
    border: 1px solid #f4f4f4;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    border-radius: 30px;
    align-items: center;
    &__gengduo {
      font-size: 24px;
    }
    &__xuanzhong {
      font-size: 20px;
    }
  }
}
.occupy {
  height: 66px;
}
</style>
