<template>
  <div class="ty-image" @dragstart="dragstart">
    <template v-if="prop.value.length">
      <el-image
        v-for="(item, index) in prop.value"
        :key="index"
        :style="[styles]"
        :src="item"
        :fit="prop.fit"
      ></el-image>
    </template>
    <div v-else class="ty-image__default">
      <i class="icon-market icon-tupiantianchong ty-image__icon"></i>
    </div>
  </div>
</template>
<script>
import utils from "@/mixins/utils";
export default {
  mixins: [utils],
  mounted() {},
  methods: {
    dragstart(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
</script>
<style lang="scss" scoped>
.ty-image {
  width: 100%;
  height: 100%;
  min-height: 30px;
  line-height: 0;
  &__default {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    font-size: 30px;
    color: #409eff;
  }
}
</style>
