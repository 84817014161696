<template>
  <div class="tools">
    <el-tabs v-model="activeName">
      <el-tab-pane label="组件库" name="com">
        <div class="components" @dragstart="handleDragStart" @dragend="dragEnd">
          <div
            v-for="(item, i) in componentList"
            :key="i"
            class="components-item"
            draggable
            :data-index="i"
            @dblclick="dblclick(item, i)"
          >
            <i class="icon-market components-item__icon" :class="item.icon"></i>
            <div class="components-item__label">{{ item.label }}</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="组件管理" name="comList">
        <used-components @onScrollTo="onScrollTo" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import componentList from '@/utils/components'
import usedComponents from '../used-components/used-components.vue'
import { guid } from '@/utils/index.js'
import { mapState } from 'vuex'
export default {
  components: { usedComponents },
  props: {
    isPush: {
      type: Boolean,
      default: false,
    },
    index: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      activeName: 'com',
      componentList,
    }
  },
  computed: {
    ...mapState(['components', 'temporary']),
  },
  mounted() {},
  methods: {
    /**
     * 开始拖拽
     */
    handleDragStart(e) {
      const data = componentList[e.target.dataset.index]
      if (this.verifyDBTyTabs(data, e)) return
      this.$store.commit('SET_TEMPORARY', data)
    },
    dragEnd() {
      // 如果该组件未在画布中释放，则将该组件的记录删除
      if (!this.temporary?.status) {
        this.$store.commit('SET_TEMPORARY', null)
        this.$emit('update:isPush', false)
        return
      }
      if (this.temporary.status === 2) return
      this.$emit('update:isPush', false)
      const data = JSON.parse(JSON.stringify(this.components))
      data.splice(this.index, 1)
      this.$store.commit('SET_COMPONENTS', data)
      this.$store.commit('SET_TEMPORARY', null)
    },
    verifyDBTyTabs(data, e = {}) {
      let has = false
      for (const e of this.components) {
        if (e.component === 'TyTabs' && data.component === 'TyTabs') {
          e.preventDefault?.()
          e.stopPropagation?.()
          has = true
          return this.$message.error('导航栏最多可添加1个')
        }
      }
      return has
    },
    dblclick(item) {
      if (this.verifyDBTyTabs(item)) return

      item = JSON.parse(JSON.stringify(item))
      item.status = 2 // 标识该组件在画布中释放的
      item.setting.props.uuid = guid(8)

      this.$store.commit('SET_COMPONENTS', [...this.components, item])
      const index = this.components.length - 1
      this.$emit('selectCom', { item, index })
      if (item.setting.style.position === 'absolute') return
      this.onScrollTo(this.components.length - 1 === index ? 'down' : '')
    },
    onScrollTo(e) {
      this.$emit('onScrollTo', e)
    },
  },
}
</script>
<style lang="scss" scoped>
// 左侧工具栏
.tools {
  width: 350px;
  height: 100%;
  // margin-top: 50px;
  background-color: #fff;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  // position: absolute;
  // top: 50px;
  // left: 0;
  // z-index: 4;
}
.components {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  &-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    background: #ffffff;
    box-shadow: 2px 2px 10px #d9d9d9, -2px -2px 10px #ffffff;
    width: 80px;
    height: 80px;
    padding: 12px;
    margin: 10px;
    overflow: hidden;
    box-sizing: border-box;
    &__icon {
      font-size: 20px;
      color: #409eff;
    }
    &__label {
      font-size: 14px;
      color: #333;
      margin-top: 4px;
    }
  }
}
</style>
