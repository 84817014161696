<template>
  <div
    v-show="menuShow"
    class="contextmenu"
    :style="{
      top: contextMenuData.top + 'px',
      left: contextMenuData.left + 'px',
    }"
  >
    <ul @mouseup="handleMouseUp">
      <li @click="deleteComponent">删除</li>

      <li v-if="contextMenuData.showZIndex" @click="topComponent">置顶</li>
      <li v-if="contextMenuData.showZIndex" @click="bottomComponent">置底</li>
      <li v-if="!contextMenuData.showZIndex" @click="upComponent">上移</li>
      <li v-if="!contextMenuData.showZIndex" @click="downComponent">下移</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    menuShow: {
      type: Boolean,
      default: false,
    },
    contextMenuData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      copyData: null,
    };
  },

  methods: {
    deleteComponent() {
      this.$emit("editItemDel");
    },
    handleMouseUp() {
      this.$emit("update:menuShow", false);
    },
    topComponent() {
      this.$emit("topComponent");
    },
    bottomComponent() {
      this.$emit("bottomComponent");
    },
    upComponent() {
      this.$emit("upComponent");
    },
    downComponent() {
      this.$emit("downComponent");
    },
  },
};
</script>

<style lang="scss" scoped>
.contextmenu {
  position: absolute;
  z-index: 1000;

  ul {
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 5px 0;
    padding: 6px 0;

    li {
      font-size: 14px;
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #606266;
      height: 34px;
      line-height: 34px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }
}
</style>
