var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-content"},[_c('draggable',{staticClass:"draggable",attrs:{"id":"draggable","animation":"300","value":_vm.components,"draggable":".edit-item"},on:{"input":(e) => _vm.$store.commit('SET_COMPONENTS', e)}},[_vm._l((_vm.components),function(item,index){return [_c('div',{key:item.setting.props.uuid,class:[
          'edit-com',
          item.setting.style.position !== 'absolute' &&
            item.status === 2 &&
            'edit-item',
          index == _vm.selectIndex && 'is-select',
        ],style:([_vm.containerStyle(index, item)]),attrs:{"data-index":index},on:{"mousedown":function($event){return _vm.start($event, index)},"contextmenu":function($event){return _vm.handleContextMenu($event, index)},"click":function($event){$event.stopPropagation();return _vm.selectCom({ item, index })}}},[(_vm.isShow)?[(item.status === 1)?_c('div',{staticClass:"edit-occupy"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(item.status === 2)?_c(item.component,{key:item.setting.props.uuid,tag:"component",staticClass:"component",attrs:{"id":item.setting.props.uuid,"setting":item.setting}}):_vm._e()]:_vm._e()],2)]}),_c('div',{staticStyle:{"height":"40px"}}),_c('contextMenu',{attrs:{"context-menu-data":_vm.contextMenuData,"menu-show":_vm.menuShow},on:{"update:menuShow":(e) => _vm.$emit('update:menuShow', e),"editItemDel":function($event){return _vm.editItemDel(_vm.contextMenuData.index)},"topComponent":_vm.topComponent,"bottomComponent":_vm.bottomComponent,"upComponent":_vm.upComponent,"downComponent":_vm.downComponent}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }