/**
 * 本算法来源于简书开源代码，详见：https://www.jianshu.com/p/fdbf293d0a85
 * 全局唯一标识符（uuid，Globally Unique Identifier）,也称作 uuid(Universally Unique IDentifier)
 * 一般用于多个组件之间,给它一个唯一的标识符,或者v-for循环的时候,如果使用数组的index可能会导致更新列表出现问题
 * 最可能的情况是左滑删除item或者对某条信息流"不喜欢"并去掉它的时候,会导致组件内的数据可能出现错乱
 * v-for的时候,推荐使用后端返回的id而不是循环的index
 * @param {Number} len uuid的长度
 * @param {Boolean} firstU 将返回的首字母置为"u"
 * @param {Nubmer} radix 生成uuid的基数(意味着返回的字符串都是这个基数),2-二进制,8-八进制,10-十进制,16-十六进制
 */
export function guid(len = 32, firstU = true, radix = null) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  radix = radix || chars.length

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    let r
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift()
    return 'u' + uuid.join('')
  } else {
    return uuid.join('')
  }
}
/**
 * 添加单位，如果有rpx，%，px等单位结尾或者值为auto，直接返回，否则加上px单位结尾
 * @param {*} value
 * @param {*} unit
 * @returns
 */
export function addUnit(value = 'auto', unit = 'px') {
  value = String(value)
  return number(value) ? `${parseInt(value) > 0 ? parseInt(value) : parseInt(value)}${unit}` : value
}

/**
 * 验证十进制数字
 */
export function number(value) {
  return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)
}

/**
 * 数组指定元素前移
 * @param {*} fieldData
 * @param {*} index
 */
export function upGo(fieldData, index) {
  if (index !== 0) {
    fieldData[index] = fieldData.splice(index - 1, 1, fieldData[index])[0]
  } else {
    fieldData.push(fieldData.shift())
  }
}

/**
 * 数组指定元素后移
 * @param {*} fieldData
 * @param {*} index
 */
export function downGo(fieldData, index) {
  if (index !== fieldData.length - 1) {
    fieldData[index] = fieldData.splice(index + 1, 1, fieldData[index])[0]
  } else {
    fieldData.unshift(fieldData.splice(index, 1)[0])
  }
}
