<template>
  <div
    class="ty-button"
    :class="[
      'ty-button--' + setting.props.type,
      'ty-button--' + setting.props.size,
      setting.props.round && 'ty-button--round',
    ]"
  >
    {{ prop.value || "请输入按钮名称" }}
  </div>
</template>
<script>
export default {
  props: {
    setting: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      prop: {},
      style: {},
    };
  },
  watch: {
    setting: {
      handler(val) {
        this.prop = val?.props || {};
        this.style = val?.style || {};
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.button-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 按钮样式
.ty-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  cursor: move;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-appearance: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &--large {
    width: 100%;
    height: 50px;
  }
  &--normal {
    padding: 0 15px;
    font-size: 14px;
  }
  &--small {
    height: 32px;
    padding: 0 8px;
    font-size: 12px;
  }
  &--mini {
    height: 24px;
    padding: 0 4px;
    font-size: 10px;
  }
  &--round {
    border-radius: 999px;
  }
  &--primary {
    color: #fff;
    background-color: #07c160;
    border: 1px solid #07c160;
  }
  &--info {
    color: #fff;
    background-color: #1989fa;
    border: 1px solid #1989fa;
  }
  &--default {
    color: #323233;
    background-color: #fff;
    border: 1px solid #ebedf0;
  }
  &--danger {
    color: #fff;
    background-color: #ee0a24;
    border: 1px solid #ee0a24;
  }
  &--warning {
    color: #fff;
    background-color: #ff976a;
    border: 1px solid #ff976a;
  }
}
</style>
