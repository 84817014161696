// 编辑器左侧组件列表
const list = [
  {
    component: "TyButton",
    label: "按钮",
    icon: "icon-24gf-stopCircle",
    id: 2,
    setting: {
      props: {
        value: "",
        sticky: false,
        type: "default",
        size: "normal",
        round: false,
        uuid: "",
        onGoto: "", // 点击跳转的路径，目前只有navigateTo的跳转
      },
      style: {
        position: "relative",
        transform: "none",
        top: "0",
        left: "0",
        zIndex: "1",
      },
    },
  },
  {
    component: "TyTransparent",
    label: "透明按钮",
    icon: "icon-suodingtouming_huaban1",
    id: 5,
    setting: {
      props: {
        size: "normal",
        uuid: "",
        scrollTo: "", // 导航子项点击后滚动的位置,其他组件的uuid
      },
      style: {
        position: "absolute",
        transform: "none",
        border: "none",
        top: "0",
        left: "0",
        zIndex: "3",
        opacity: "0",
        borderRadius: "0",
      },
    },
  },
  {
    component: "TyImage",
    label: "图片",
    icon: "icon-tupiantianchong",
    id: 3,
    setting: {
      props: {
        value: [], // 多张图片
        fit: "widthFix",
        sticky: false,
        uuid: "",
        onGoto: "", // 点击跳转的路径，目前只有navigateTo的跳转
      },
      style: {
        width: "100%",
        height: "",
        borderRadius: "0",
        position: "relative",
        transform: "none",
        top: "0",
        left: "0",
        zIndex: "1",
      },
    },
  },
  {
    component: "TyTabs",
    label: "导航栏",
    icon: "icon-daohang",
    id: 4,
    setting: {
      props: {
        value: "", // 背景图
        sticky: false, // 是否吸顶
        fit: "widthFix",
        uuid: "",
      },
      style: {
        width: "100%",
        height: "",
        position: "relative",
        transform: "none",
        borderRadius: "0",
        top: "0",
        left: "0",
        zIndex: "1",
      },
      item: [
        {
          scrollTo: "", // 导航子项点击后滚动的位置,其他组件的uuid
          width: "", // 子项宽度
          height: "", // 子项高度
          url: "", // 未选中图片
          activeUrl: "", // 选中图片
        },
        {
          scrollTo: "", // 导航子项点击后滚动的位置,其他组件的uuid
          width: "",
          height: "",
          url: "",
          activeUrl: "",
        },
      ], // 导航项
    },
  },
];

export default list;
