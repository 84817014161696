<template>
  <div class="used">
    <draggable
      animation="300"
      :value="components"
      draggable=".used-item"
      @input="(e) => $store.commit('SET_COMPONENTS', e)"
    >
      <div
        v-for="(item, index) in components"
        :key="index"
        class="used-item"
        :data-index="index"
        @click="selectCom(item, index)"
      >
        <i class="icon-market used-item__icon" :class="item.icon"></i>
        <div class="used-item__label">{{ item.label }}</div>
        <i class="icon-market icon-shanchu used-item__del" @click="onDel(index)"></i>
      </div>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
export default {
  components: { draggable },

  data() {
    return {};
  },
  computed: {
    ...mapState(["components"]),
  },
  mounted() {},
  methods: {
    onDel(index) {
      const data = this.components.filter((e, i) => {
        if (i !== index) {
          return e;
        }
      });
      this.$store.commit("SET_COMPONENTS", data);
    },
    selectCom(item, index) {
      this.$store.commit("SET_SELECT_COMPONENTS", { item, index });
      this.menuShow = false;
      this.$emit("onScrollTo", item.setting.props.uuid);
    },
  },
};
</script>
<style lang="scss" scoped>
.used {
  &-item {
    display: flex;
    align-items: center;
    margin: 12px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 6px 12px;
    border-radius: 10px;
    margin-bottom: 12px;
    position: relative;
    background-color: #fff;
    &__icon {
      font-size: 20px;
      margin-right: 10px;
      color: #409eff;
    }
    &__label {
      font-size: 16px;
      color: #333;
    }
    &__del {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #ff0000;
      font-size: 16px;
    }
  }
}
</style>
