<template>
  <div ref="tabs" class="ty-tabs" :style="styles">
    <template v-if="prop.value || items[0].url">
      <img v-if="prop.value" class="ty-tabs__bg" :src="prop.value" alt="" />
      <div class="ty-tabs-content">
        <img
          v-for="(item, index) in items"
          :key="index"
          class="ty-tabs-item__img"
          :style="{
            width: addUnit(item.width),
            height: addUnit(item.height),
          }"
          :src="item.url"
          @click="scrollTo(item, index)"
          @mousedown="scrollTo(item, index)"
        />
      </div>
    </template>
    <div v-else class="ty-tabs__default">
      <i class="icon-market icon-daohang ty-tabs__icon"></i>
    </div>
  </div>
</template>
<script>
import utils from "@/mixins/utils";
import { addUnit } from "@/utils/index.js";
export default {
  mixins: [utils],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    setting: {
      handler(val) {
        console.log(val);
        this.items = val?.item || [];
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addUnit,
    scrollTo(item) {
      console.log("tabs的点击", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.ty-tabs {
  position: relative;
  z-index: 1;
  min-height: 20px;
  &__bg {
    width: 100%;
    // height: 100%;
    object-fit: contain;
    line-height: 0;
    vertical-align: top;
  }
  &-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &-item {
    &__img {
      vertical-align: top;
      line-height: 0;
      object-fit: contain;
    }
  }
  &__default {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    font-size: 30px;
    color: #409eff;
  }
}
.sticky {
  position: sticky;
  top: -2px;
  z-index: 10;
}
</style>
