<template>
  <div class="config">
    <el-tabs v-model="activeName">
      <el-tab-pane v-if="selectComponent.component" label="样式设置" name="container">
        <!-- <el-scrollbar style="height: 100%"> -->
        <el-form ref="form" class="form" size="mini" :model="form" label-width="120px">
          <el-form-item label="唯一标识：">
            <el-input v-model="form.props.uuid" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item v-if="selectComponent.component === 'TyButton'" label="按钮类型：">
            <el-select v-model="form.props.type" placeholder="按钮类型">
              <el-option
                v-for="item in buttonTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
              selectComponent.component === 'TyButton' ||
              selectComponent.component === 'TyTransparent'
            "
            label="按钮尺寸："
          >
            <el-select v-model="form.props.size" placeholder="按钮尺寸">
              <el-option
                v-for="item in buttonSizeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="selectComponent.component === 'TyButton'" label="圆形按钮：">
            <el-switch v-model="form.props.round" :active-value="true" :inactive-value="false">
            </el-switch>
          </el-form-item>

          <el-form-item
            v-if="
              selectComponent.component !== 'TyButton' &&
              selectComponent.component !== 'TyTransparent'
            "
            label="宽度："
            required
          >
            <el-input
              v-model="form.style.width"
              :disabled="form.props.fit === 'heightFix'"
              placeholder="请输入宽度，最大宽度375"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              selectComponent.component !== 'TyButton' &&
              selectComponent.component !== 'TyTransparent'
            "
            label="高度："
          >
            <el-input
              v-model.trim="form.style.height"
              :disabled="form.props.fit === 'widthFix'"
              placeholder="请输入高度"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              selectComponent.component !== 'TyButton' &&
              selectComponent.component !== 'TyTransparent'
            "
            label="圆角："
          >
            <el-input
              v-model.trim.number="form.style.borderRadius"
              placeholder="请输入圆角"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="selectComponent.component === 'TyImage' || selectComponent.component === 'TyTabs'"
            label="图片自适应："
          >
            <el-select v-model="form.props.fit" placeholder="请选择" @change="changeImageFil">
              <el-option
                v-for="item in imageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="selectComponent.component === 'TyTabs'" label="是否吸顶：">
            <el-switch
              v-model="form.props.sticky"
              :active-value="true"
              :inactive-value="false"
              @change="stickyChange"
            >
            </el-switch>
          </el-form-item>
          <el-form-item v-if="selectComponent.component !== 'TyTabs'" label="是否悬浮：">
            <el-switch
              v-model="form.style.position"
              active-value="absolute"
              inactive-value="relative"
              @change="positionChange"
            >
            </el-switch>
          </el-form-item>
          <template v-if="form.style.position === 'absolute'">
            <el-form-item label="X轴：">
              <el-input v-model.trim.number="form.style.left" placeholder="请输入X轴"></el-input>
            </el-form-item>
            <el-form-item label="Y轴：">
              <el-input v-model.trim.number="form.style.top" placeholder="请输入Y轴"></el-input>
            </el-form-item>
            <el-form-item label="层级：">
              <el-input v-model.trim.number="form.style.zIndex" placeholder="请输入层级"></el-input>
            </el-form-item>
            <el-form-item label="左右居中：">
              <el-switch
                v-model="absoluteCenter"
                :active-value="true"
                :inactive-value="false"
                @change="absoluteCenterChange"
              >
              </el-switch>
            </el-form-item>
          </template>
        </el-form>
        <!-- </el-scrollbar> -->
      </el-tab-pane>
      <el-tab-pane v-if="selectComponent.component" label="内容设置" name="content">
        <el-form
          ref="form"
          size="mini"
          class="form"
          :model="form"
          label-width="120px"
          label-position="top"
        >
          <el-form-item
            v-if="
              selectComponent.component !== 'TyTabs' &&
              selectComponent.component !== 'TyTransparent'
            "
            label="点击跳转至："
          >
            <el-input v-model="form.props.onGoto" placeholder="如：/pages/index/index"></el-input>
          </el-form-item>
          <el-form-item v-if="selectComponent.component === 'TyTransparent'" label="滚动位置：">
            <el-input
              v-model="form.props.scrollTo"
              placeholder="请输入滚动位置的唯一字符串"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="selectComponent.component === 'TyButton'" label="本文：" required>
            <el-input
              v-model="form.props.value"
              :maxlength="8"
              placeholder="请输入文本，如：确 定"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="selectComponent.component === 'TyImage'" label="上传图片：" required>
            <upload-img :files="form.props.value" @upload="uploadImageChange"> </upload-img>
          </el-form-item>
          <template v-if="selectComponent.component === 'TyTabs'">
            <el-form-item label="上传背景图片：" required>
              <upload-img :files="form.props.value" :multiple="false" @upload="uploadImageChange">
              </upload-img>
            </el-form-item>
            <draggable v-model="form.item" animation="300" draggable=".tabs-item">
              <div v-for="(item, index) in form.item" :key="index" class="tabs-item">
                <div class="flex flex-a-c" style="margin-top: 20px">
                  <div class="flex flex-a-c">
                    <label class="lable required" style="width: 40px" for="">宽：</label>
                    <el-input
                      v-model="item.width"
                      clearable
                      size="mini"
                      :maxlength="4"
                      placeholder="请输入宽度"
                    ></el-input>
                  </div>
                </div>

                <div class="flex flex-a-c flex-j-bet" style="margin-top: 20px">
                  <div>
                    <label class="lable required" style="margin-bottom: 10px" for=""
                      >未选中图片：</label
                    >
                    <upload-img :files="item.url" :multiple="false" @upload="(e) => (item.url = e)">
                    </upload-img>
                  </div>
                  <div>
                    <label class="lable required" style="margin-bottom: 10px" for=""
                      >选中图片：</label
                    >
                    <upload-img
                      :files="item.activeUrl"
                      :multiple="false"
                      @upload="(e) => (item.activeUrl = e)"
                    >
                    </upload-img>
                  </div>
                </div>
                <div class="flex flex-a-c" style="margin-top: 20px">
                  <div class="flex flex-a-c">
                    <label class="lable" style="width: 120px" for="">滚动位置：</label>
                    <el-input
                      v-model="item.scrollTo"
                      style="user-select: all"
                      clearable
                      size="mini"
                      :maxlength="25"
                      placeholder="请输入滚动位置的唯一字符串"
                    ></el-input>
                  </div>
                </div>
                <div class="icon-market icon-shanchu tabs-item__del" @click="delTab(index)"></div>
              </div>
            </draggable>
            <div class="tabs-item__add" @click="addTab">添加导航项</div>
          </template>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import uploadImg from '@/components/ty-upload-image/index.vue'
import enumeration from './enumeration.js'
import { mapState } from 'vuex'
export default {
  components: { draggable, uploadImg },
  data() {
    return {
      form: {},
      page: {},
      absoluteCenter: false,
      showPage: true,
      activeName: 'container',
      ...enumeration,
    }
  },
  computed: {
    ...mapState(['selectComponent', 'selectIndex']),
  },
  watch: {
    selectComponent: {
      handler(val) {
        this.form = val?.setting || {}
        // 根据transform来判断图片是否居中
        if (val.setting && val.setting.style.transform !== 'none') {
          this.absoluteCenter = true
        } else if (val.setting && val.setting.style.transform === 'none') {
          this.absoluteCenter = false
        }
      },
      immediate: true,
      deep: true,
    },
    pages: {
      handler(val) {
        this.page = val || {}
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    /**
     * 根据图片自适应模式，处理宽高
     */
    changeImageFil(e) {
      console.log(e)
      if (e === 'widthFix') {
        this.form.style.height = ''
        if (!this.form.style.width) {
          this.form.style.width = '100%'
        }
      } else if (e === 'heightFix') {
        this.form.style.width = ''
        if (!this.form.style.height) {
          this.form.style.height = '300'
        }
      } else if (!this.form.style.height) {
        this.form.style.height = '200'
      }
    },
    /**
     * 是否悬浮
     */
    positionChange(e) {
      console.log(e)
      // 因为按钮在悬浮的时候不需要去设置width，width由自身内容与size决定
      if (
        e === 'absolute' &&
        this.selectComponent.component !== 'TyButton' &&
        this.selectComponent.component !== 'TyTransparent'
      ) {
        this.form.style.width = '100%'
        this.form.style.zIndex = '2'
        console.log(this.form)
      } else if (e === 'absolute') {
        this.form.style.zIndex = '2'
        // if (this.selectComponent.component !== "TyTabs") {
        //   this.form.style.zIndex = "999";
        // }
      } else {
        this.form.style.top = '0'
        this.form.style.zIndex = '1'
        this.absoluteCenterChange()
      }
    },
    /**
     * 悬浮元素是否居中切换事件
     */
    absoluteCenterChange(e) {
      console.log(e)
      if (e) {
        this.form.style.left = '50%'
        this.form.style.transform = 'translateX(-50%)'
      } else {
        this.form.style.left = '0'
        this.form.style.transform = 'none'
      }
    },
    /**
     * 添加导航子项目
     */
    addTab() {
      this.form.item.push({
        scrollTo: '', // 导航子项点击后滚动的位置,其他组件的uuid
        width: '',
        height: '',
        url: '',
        activeUrl: '',
      })
    },
    delTab(index) {
      this.form.item = this.form.item.filter((e, i) => i !== index)
    },
    dragstart(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    uploadImageChange(e) {
      console.log('上传图片完成', e)
      this.$set(this.form.props, 'value', e)
    },
    stickyChange(e) {
      console.log(e)
      console.log(this.form)
      if (e) {
        this.$set(this.form.style, 'zIndex', '999')
        this.$set(this.form.style, 'top', '-2')
      } else {
        this.$set(this.form.style, 'zIndex', '1')
        this.$set(this.form.style, 'top', '0')
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/base.less';
.config {
  width: 400px;
  height: 100%;
  // margin-top: 50px;
  background-color: #fff;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  // position: absolute;
  // top: 50px;
  // right: 0;
  // z-index: 4;
}
.title {
  font-size: 18px;
}
.tabs-item {
  margin: 12px;
  // border: 1px solid #409eff;
  padding: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: inset 2px 2px 10px #d9d9d9, inset -2px -2px 10px #ffffff;
  position: relative;
}
.tabs-item__add {
  width: 200px;
  height: 32px;
  background-color: #409eff;
  border-radius: 12px;
  margin: 12px auto;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: default;
}
.tabs-item__del {
  font-size: 18px;
  color: #c0c4cc;
  position: absolute;
  top: 6px;
  right: 6px;
}

.avatar-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .el-upload {
    &:hover {
      border-color: #409eff;
    }
  }
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
::v-deep .avatar {
  width: 120px;
  height: 120px;
  display: block;
  object-fit: contain;
}

::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
// ::v-deep .el-tabs__content::-webkit-scrollbar {
//   // display: none;
// }
::v-deep .el-upload-list__item {
  transition: none !important;
}
::v-deep .el-tabs {
  height: 100%;
}

::v-deep .el-tabs__content {
  height: calc(100% - 94px);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.lable {
  font-size: 14px;
  color: #606266;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.required {
  &::before {
    content: '*';
    color: #ff4949;
    margin-right: 4px;
  }
}
::v-deep .el-upload-list__item-thumbnail {
  object-fit: contain;
}
</style>
