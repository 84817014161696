<template>
  <div class="edit-content">
    <!-- 列表内容，上下拖动 -->
    <draggable
      id="draggable"
      animation="300"
      :value="components"
      draggable=".edit-item"
      class="draggable"
      @input="(e) => $store.commit('SET_COMPONENTS', e)"
    >
      <template v-for="(item, index) in components">
        <!-- 每个组件的容器，包含虚线部分 -->
        <div
          :key="item.setting.props.uuid"
          :data-index="index"
          :style="[containerStyle(index, item)]"
          :class="[
            'edit-com',
            item.setting.style.position !== 'absolute' &&
              item.status === 2 &&
              'edit-item',
            index == selectIndex && 'is-select',
          ]"
          @mousedown="start($event, index)"
          @contextmenu="handleContextMenu($event, index)"
          @click.stop="selectCom({ item, index })"
        >
          <template v-if="isShow">
            <!-- 未释放时占位的 -->
            <div v-if="item.status === 1" class="edit-occupy">
              {{ item.label }}
            </div>
            <!-- 动态组件 -->
            <component
              :is="item.component"
              v-if="item.status === 2"
              :id="item.setting.props.uuid"
              :key="item.setting.props.uuid"
              :setting="item.setting"
              class="component"
            ></component>
          </template>
        </div>
      </template>
      <!-- 占位 -->
      <div style="height: 40px" />
      <!-- 右击弹窗 -->
      <contextMenu
        :context-menu-data="contextMenuData"
        :menu-show="menuShow"
        @update:menuShow="(e) => $emit('update:menuShow', e)"
        @editItemDel="editItemDel(contextMenuData.index)"
        @topComponent="topComponent"
        @bottomComponent="bottomComponent"
        @upComponent="upComponent"
        @downComponent="downComponent"
      />
    </draggable>
  </div>
</template>
<script>
import TyImage from "@/components/ty-image/index.vue";
import TyNavbar from "@/components/ty-navbar/index.vue";
import TyButton from "@/components/ty-button/index.vue";
import TyTabs from "@/components/ty-tabs/index.vue";
import TyTransparent from "@/components/ty-transparent/index.vue";
import contextMenu from "../context-menu/index.vue";
import draggable from "vuedraggable";
import { addUnit, upGo, downGo } from "@/utils/index.js";
import { mapState } from "vuex";
export default {
  components: {
    TyImage,
    TyButton,
    TyNavbar,
    TyTabs,
    draggable,
    contextMenu,
    TyTransparent,
  },
  props: {
    menuShow: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contextMenuData: {},
      show: false,
    };
  },
  // watch: {
  //   handler(val) {
  //     this.show = val;
  //   },
  //   immediate: true,
  // },
  computed: {
    ...mapState(["components", "selectComponent", "selectIndex", "temporary"]),
  },
  mounted() {},
  methods: {
    /**
     * 定义组件容器的位置与宽高
     */
    containerStyle(index, item) {
      return {
        position: item.setting.props.sticky ? "sticky" : item.setting.style.position,
        top: addUnit(item.setting.style.top),
        left: addUnit(item.setting.style.left),
        transform: item.setting.style.transform,
        zIndex: item.setting.style.zIndex,
        width: addUnit(item.setting.style.width),
        height: item.setting.style.height ? addUnit(item.setting.style.height) : "",
      };
    },
    /**
     * 组件悬浮，开始移动
     */
    start(e, index) {
      e.stopPropagation();
      if (this.components[index].setting.style.position !== "absolute") return;
      const pos = { ...this.components[index].setting.style };
      if (pos.transform !== "none") {
        pos.transform = "none";
        pos.left = 0;
      }
      const startY = e.clientY;
      const startX = e.clientX;
      // 如果直接修改属性，值的类型会变为字符串，所以要转为数值型
      const startTop = Number(pos.top);
      const startLeft = Number(pos.left);
      const doc = document.getElementById(this.components[index].setting.props.uuid);
      const width = doc.getBoundingClientRect().width;
      // const height = doc.getBoundingClientRect().height;
      const move = (moveEvent) => {
        const currX = moveEvent.clientX;
        const currY = moveEvent.clientY;
        pos.top = currY - startY + startTop;
        pos.left = currX - startX + startLeft;

        // 处理悬浮元素，禁止移出画布之外
        if (pos.top < 0) pos.top = 0;
        if (pos.left < 0) pos.left = 0;
        if (pos.left + width > 375) pos.left = 375 - width;
        // 修改当前组件样式
        this.components[index].setting.style = pos;
      };

      const up = () => {
        document.removeEventListener("mousemove", move);
        document.removeEventListener("mouseup", up);
      };

      document.addEventListener("mousemove", move);
      document.addEventListener("mouseup", up);
    },
    /**
     * 点击组件，打开鼠标右击菜单
     */
    handleContextMenu(e, index) {
      e.stopPropagation();
      e.preventDefault();
      // 计算菜单相对于编辑器的位移
      let target = e.target;
      let top = e.offsetY;
      let left = e.offsetX;

      while (target instanceof SVGElement) {
        target = target.parentNode;
      }
      while (!target.className.includes("draggable")) {
        console.log(target.offsetTop, target.offsetLeft);
        left += target.offsetLeft;
        top += target.offsetTop;
        target = target.parentNode;
      }
      this.contextMenuData = {
        index,
        left,
        top,
        showZIndex:
          this.components[index].setting.style.position === "absolute",
      };
      this.$emit("update:menuShow", true);
    },
    /**
     * 点击画布中的组件，
     * 右侧显示当前组件的设置项
     */
    selectCom(e) {
      console.log(e);
      this.$store.commit("SET_SELECT_COMPONENTS", e);
      this.$emit("update:menuShow", false);
    },
    /**
     * 删除画布中的指定组件
     */
    editItemDel(index) {
      this.components.splice(index, 1);
      this.$store.commit("SET_SELECT_COMPONENTS", { item: {}, index: 0 });
    },
    /**
     * 置顶
     */
    topComponent() {
      if (this.components.length < 2) return;
      let max = 0;
      this.components.forEach((e) => {
        console.log(e);
        if (max < Number(e.setting.style.zIndex)) {
          if (e.component === "TyTabs" && e.setting.props.sticky) return;
          max = Number(e.setting.style.zIndex);
        }
      });
      max += 1;
      this.components[this.contextMenuData.index].setting.style.zIndex = max;
    },
    /**
     * 置底
     */
    bottomComponent() {
      if (this.components.length < 2) return;

      this.components[this.contextMenuData.index].setting.style.zIndex = 0;
    },
    /**
     * 上移
     */
    upComponent() {
      if (this.components.length < 2) return;
      if (this.contextMenuData.index === 0) return;
      upGo(this.components, this.contextMenuData.index);
    },
    /**
     * 下移
     */
    downComponent() {
      if (this.components.length < 2) return;
      if (this.components.length - 1 === this.contextMenuData.index) return;
      downGo(this.components, this.contextMenuData.index);
    },
  },
};
</script>
<style lang="scss" scoped>
//中间预览区域
.draggable {
  position: relative;
  z-index: 2;
  flex: 1;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100%;
}
.draggable::-webkit-scrollbar {
  display: none;
}
.edit {
  // &-com {
  // }
  &-content {
    flex: 1;
    width: 100%;
    overflow: hidden;
    position: relative;
    user-select: none;
  }
  &-occupy {
    width: 100%;
    height: 40px;
    background-color: #deedff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  //悬浮元素变成行内块
  &-item {
    outline: none;
    user-select: none;
    display: inline-block;
    vertical-align: top; //解决行内块元素之间有间隙的问题
    div {
      pointer-events: none;
    }
  }
}

.is-select {
  .component {
    transform: rotate(0.9);
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: transparent;
    border: 2px dashed #409eff;
    pointer-events: none;
    content: " ";
  }
}
</style>
