export default {
  predefineColors: [
    "#333",
    "#fff",
    "#e99b5a",
    "rgba(0,0,0,0)",
    "#ff0000",
    "#67c23a",
    "#409EFF",
  ],
  layoutOptions: [
    {
      label: "靠左排列",
      value: "flex-start",
    },
    {
      label: "左右居中",
      value: "center",
    },
    {
      label: "靠右排列",
      value: "flex-end",
    },
    {
      label: "第一个元素靠起点，最后一个元素靠终点，余下元素平均分配空间",
      value: "space-between",
    },
  ],
  weightOptions: [
    {
      label: "默认",
      value: "500",
    },
    {
      label: "细体",
      value: "400",
    },
    {
      label: "加粗",
      value: "700",
    },
  ],
  imageOptions: [
    {
      label: "拉伸以适应内容框",
      value: "fill",
    },
    {
      label: "保持纵横比，短边无法铺满时添加黑边",
      value: "contain",
    },
    {
      label: "保持纵横比，长边剪裁以适应内容框。",
      value: "cover",
    },
    {
      label: "保持纵横比，宽度不变，高度自动变化(推荐)",
      value: "widthFix",
    },
    // {
    //   label: "保持纵横比，高度不变，宽度自动变化",
    //   value: "heightFix",
    // },
  ],
  buttonTypeOptions: [
    {
      label: "主色(绿色)",
      value: "primary",
    },
    {
      label: "信息(蓝色)",
      value: "info",
    },
    {
      label: "默认(白色)",
      value: "default",
    },

    {
      label: "警告(黄色)",
      value: "warning",
    },
    {
      label: "危险(红色)",
      value: "danger",
    },
  ],
  buttonSizeOptions: [
    {
      label: "大号按钮",
      value: "large",
    },
    {
      label: "普通按钮",
      value: "normal",
    },
    {
      label: "小型按钮",
      value: "small",
    },
    {
      label: "迷你按钮",
      value: "mini",
    },
  ],
};
