/* eslint-disable no-unused-vars */
import { addUnit } from "@/utils/index.js";
const utils = {
  props: {
    setting: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    setting: {
      handler(val) {
        this.prop = val?.props || {};
        this.style = val?.style || {};
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      prop: {},
      style: {},
    };
  },
  computed: {
    styles() {
      let style = {};
      const {
        width,
        height,
        fontSize,
        borderRadius,
        top,
        left,
        right,
        bottom,
        position,
        transform,
        ...sty
      } = this.style;
      if (width) {
        style.width = addUnit(width);
      }
      if (height) {
        style.height = addUnit(height);
      }
      if (fontSize) {
        style.fontSize = addUnit(fontSize);
      }
      if (borderRadius) {
        style.borderRadius = addUnit(borderRadius);
      }
      // if (top) {
      //   style.top = addUnit(top);
      // }
      // if (left) {
      //   style.left = addUnit(left);
      // }
      // if (right) {
      //   style.right = addUnit(right);
      // }
      // if (bottom) {
      //   style.bottom = addUnit(bottom);
      // }
      // if (position) {
      //   style.bottom = addUnit(bottom);
      // }
      // if (transform) {
      //   style.bottom = addUnit(bottom);
      // }
      style = { ...style, ...sty };

      return style;
    },
  },
};
export default utils;
